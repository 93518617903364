import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getInstances } from '../../store/ModuleAction';
import { subscribe } from '../../store/NotificationAction';
import * as Helper from '../../util/Helper';
import StatusCode from '../../util/StatusCode';
import TableHorizontal from '../misc/TableHorizontal';
import Pagination from '../misc/Pagination';
import { useParams, Link } from 'react-router-dom';
import DownloadFilesForInstance from "./components/DownloadFilesForInstance";


const ModuleInstanceRun = () => {
    const { moduleId } = useParams();
    const dispatch = useDispatch();
    const [lastMessageId, setMessageId] = useState(undefined);

    const instances = useSelector(state => state.module.instances);
    const notification = useSelector(state => state.notification);
    const module = useSelector(state => state.module.moduleStatus);

    useEffect(() => {
        getInstances(moduleId)(dispatch);
        return subscribe(['moduleInstanceStart', 'moduleInstanceComplete'])(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (instances.code === StatusCode.COMPLETE && lastMessageId !== notification.messageId) {
            setMessageId(notification.messageId);
            getInstances(moduleId, instances.filter)(dispatch);
        }
    })

    const onChangePage = (p) => {
        getInstances(moduleId, { ...instances.filter, page: p })(dispatch);
    }

    const dropdownMenu = (moduleInstanceId, completed) => (
        <DownloadFilesForInstance
            moduleInstanceId={moduleInstanceId}
            completed={completed}
            fileDownloadEnabled={module.data?.fileDownloadEnabled}
            moduleId={moduleId}
        />
    )

    const renderInstances = () => {
        const newList = instances.data?.map(instance => ({
            id: instance.id,
            data: [
                { value: Helper.formatDate(instance.startedAt) },
                { value: Helper.formatDate(instance.completedAt) },
                { value: instance.countCritical },
                { value: instance.countError },
                { value: instance.countWarning },
                { value: <Link to={`/module/${moduleId}/instance/${instance.id}`}>Visa loggar</Link> },
                { value: dropdownMenu(instance.id, instance.completedAt) }
            ]
        })) ?? [];

        //convert action to this format (CachedRequestState)
        const requestState = {
            code: instances.code,
            data: {
                currentPage: instances.currentPage ?? 0,
                totalCount: instances.totalCount ?? 0,
                pageSize: instances.pageSize ?? 0,
                totalPages: instances.totalPages ?? 0,
                values: instances.data
            }
        };

        return (<>
            <TableHorizontal
                header={[{ value: 'Startdatum' }, { value: 'Slutdatum' }, { value: 'Kritiska fel' }, { value: 'Fel' }, { value: 'Varningar' }, { value: 'Loggar' }, { value: '' }]}
                info={newList}
                error={instances.code === StatusCode.ERROR}
                searching={instances.code === StatusCode.PENDING}
            />
            {instances.code === StatusCode.COMPLETE &&
                <Pagination requestState={requestState} onChangePage={(p) => onChangePage(p)} className="no-padding" />
            }
        </>);
    }

    return (<>
        {renderInstances()}
    </>);
}

export default ModuleInstanceRun;

import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import SchoolSoft from './modules/SchoolSoft';
import SchoolSoftSchedule from './modules/SchoolSoftSchedule';
import Edlevo from './modules/Edlevo';
import ExportOneRosterConfiguration from './modules/ExportOneRosterConfiguration';
import ExportAdditionalTeam from './modules/ExportAdditionalTeam';
import LocalAD from './modules/LocalAD';
import LocalADSchedule from './modules/LocalADSchedule';
import Skola24Schedule from './modules/Skola24Schedule';
import ScheduleSyncGoogle from './modules/ScheduleSyncGoogle';
import GoogleClassroomExport from './modules/GoogleClassroomExport';
import EduCloud from './modules/EduCloud';
import AzureADGroupExport from './modules/AzureADGroupExport';
import GoogleGroupExport from './modules/GoogleGroupExport';
import HaldorExport from './modules/HaldorExport';
import SS12000API from './modules/SS12000API';
import GoogleUserExport from './modules/GoogleUserExport';
import AppleSchoolManagerExport from './modules/AppleSchoolManagerExport';
import DNPExport from './modules/DNPExport';
import SDSv21CsvImportConfig from './modules/SDSv21CsvImportConfig';

const None = () => null;

/* Register configuration component for module here using ModuleTypeName as key.
 * If no specific configuration is required use the 'None' component.
 */
const configurationComponents = {
    SchoolSoft: SchoolSoft,
    SchoolSoftSchedule: SchoolSoftSchedule,
    TietoEducation: Edlevo,
    OneRosterConfiguration: ExportOneRosterConfiguration,
    AdditionalTeam: ExportAdditionalTeam,
    LocalAD: LocalAD,
    LocalADSchedule: LocalADSchedule,
    Skola24Schedule: Skola24Schedule,
    AzureADImport: None,
    ScheduleSyncGoogle: ScheduleSyncGoogle,
    GoogleClassroomExport: GoogleClassroomExport,
    EduCloud: EduCloud,
    AzureADGroupExport: AzureADGroupExport,
    GoogleGroupExport: GoogleGroupExport,
    HaldorExport: HaldorExport,
    SS12000API: SS12000API,
    ScheduleSyncExchange: None,
    GoogleUserExport: GoogleUserExport,
    AppleSchoolManager: AppleSchoolManagerExport,
    DNPExport: DNPExport,
    SDSv21CsvImport: SDSv21CsvImportConfig,
};

const ModuleConfiguration = ({ type, isAdmin, module, updates, ...props }) => {
    const ConfigurationComnponent = configurationComponents[type];

    if (ConfigurationComnponent) {
        return <ConfigurationComnponent disableInput={!isAdmin} configuration={module.configuration} updates={updates.configuration || {}} {...props} moduleDisplayName={module.moduleTypeDisplayName}/>;
    }

    return <Card className="edit_module">
        <CardHeader tag="h2">Okänd modultyp</CardHeader>
        <CardBody>
            <p>Modultypen '{type}' saknar konfigurationskomponent.</p>
        </CardBody>
    </Card>;
};

export default ModuleConfiguration;

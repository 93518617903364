import React, { Component } from 'react';
import { Input, Form, Button } from 'reactstrap';
import FontAwesomeButton from '../../../../misc/FontAwesomeButton';
import ModalComponent from '../../../../misc/ModalComponent';


const defaultFilter = {
    type: 0,
    value: '',
}

const convertTypeEnum = (value) => {
    switch (value) {
        case 0: return 'Okänt';
        case 1: return 'Ämne';
        case 2: return 'Kurs';
        case 3: return 'Ämne eller Kurs';
    }
}

const filterEquals = (a, b) => a.type === b.type && a.value === b.value;

class Skola24FilterInput extends Component {
    state = {
        filters: [],
        currentIndex: null,
        currentFilter: { ...defaultFilter },
        isOpen: false,
        deleteFilter: {},
    }

    componentDidMount() {
        this.setState({ filters: this.props.filters });
    }

    submitFilter = (e) => {
        e.preventDefault()
        const filters = this.calculateUpdatedFilters();
        if (filters) {
            this.setState({ filters, currentIndex: null, currentFilter: { ...defaultFilter } });
            this.notifyChange(filters);
        } else {
            this.setState({ currentIndex: null, currentFilter: { ...defaultFilter } });
        }
    }

    calculateUpdatedFilters = () => {
        if (Number.isFinite(this.state.currentIndex)) {
            return this.state.filters.map((x, i) => i === this.state.currentIndex ? this.state.currentFilter : x);
        } else if (!this.state.filters.some(x => filterEquals(x, this.state.currentFilter))) {
            return [...this.state.filters, this.state.currentFilter];
        } else {
            return null;
        }
    }

    editFilter = (index) => {
        this.setState({
            currentIndex: index,
            currentFilter: { ...this.state.filters[index] },
        });
    }

    removeFilter = (index) => {
        const filterIndex = this.state.filters[index];
        this.setState({ isOpen: true, deleteFilter: { value: filterIndex.value, type: filterIndex.type, index: index } })
    }

    renderModal() {
        return (
            <ModalComponent
                isOpen={this.state.isOpen}
                header="Radera filter"
                disabled={this.props.disabled}
                confirm="Ja"
                cancel="Nej"
                toggleModal={this.toggleModal}
                update={this.updateFilter}
            >
                <div>
                    <p>Vill du verkligen radera filtret "{convertTypeEnum(this.state.deleteFilter.type)}: {this.state.deleteFilter.value}"?</p>
                    <p>Om du ångrar dig senare måste du lägga till ett nytt filter med exakt samma värde.</p>
                </div>
            </ModalComponent>
        )
    }

    abort = () => {
        this.setState({ currentIndex: null, currentFilter: { ...defaultFilter } })
    }

    toggleModal = () => {
        this.setState({ isOpen: false, deleteFilter: {} })
    }

    updateFilter = () => {
        const filters = this.state.filters.filter((_, i) => i !== this.state.deleteFilter.index);
        this.setState({ filters });
        this.notifyChange(filters);
        this.toggleModal();
    }

    valid = () => {
        if (!this.state.currentFilter) {
            return false;
        }

        if (this.state.currentFilter.type < 1 || this.state.currentFilter.type > 3) {
            return false;
        }

        if (!this.state.currentFilter.value || this.state.currentFilter.value.length === 0) {
            return false;
        }

        return true;
    }

    notifyChange = (filters) => {
        this.props.onChange({ target: { name: this.props.name, value: filters } });
    }

    updateType = (e) => {
        this.setState({
            currentFilter: {
                ...this.state.currentFilter,
                type: parseInt(e.target.value, 10),
            }
        });
    }

    updateValue = (e) => {
        this.setState({
            currentFilter: {
                ...this.state.currentFilter,
                value: e.target.value,
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Form className="gridList" style={{ gridTemplateColumns: '4fr 12fr 4fr' }} onSubmit={(e) => this.submitFilter(e)}>
                    <h6>Matchar med</h6>
                    <h6>Värde</h6>
                    <h6></h6>
                    {this.state.filters.map((filter, index) => this.renderRow(index, filter))}
                    {this.renderRow(null, this.state.currentFilter)}
                </Form>
                {this.state.isOpen && this.renderModal()}
            </React.Fragment>);
    }

    renderRow(index, filter) {
        const editing = Number.isFinite(this.state.currentIndex);

        if (index === this.state.currentIndex) {
            return (<React.Fragment key={index}>
                <div>
                    <select disabled={this.props.disabled} className="form-control" value={this.state.currentFilter.type} onChange={this.updateType}>
                        <option disabled value={0}>Välj typ</option>
                        <option value={1} >{convertTypeEnum(1)}</option>
                        <option value={2} >{convertTypeEnum(2)}</option>
                        <option value={3} >{convertTypeEnum(3)}</option>
                    </select>
                </div>
                <div>
                    <Input type="text" disabled={this.props.disabled} placeholder="Ange värde som ska filtreras bort" autoComplete="off" value={this.state.currentFilter.value} onChange={this.updateValue} />
                </div>
                <div className={editing ? 'contentCapacity2' : ''}>
                    <div>
                        <Button color="primary" type="submit" disabled={this.props.disabled || !this.valid()}>{editing ? 'Spara' : 'Lägg till'}</Button>
                    </div>
                    {editing && <div><Button color="danger" onClick={() => this.abort()}>Avbryt</Button></div>}
                </div>
            </React.Fragment>)
        } else if (Number.isFinite(index)) {
            return (<React.Fragment key={index}>
                <div>{convertTypeEnum(filter.type)}</div>
                <div>{filter.value}</div>
                <div className="contentCapacity2">
                    <div className="centerContent">
                        <FontAwesomeButton icon="pen" onClick={() => this.editFilter(index)} disabled={this.props.disabled || editing} title="Redigera filter" />
                    </div>
                    <div className="centerContent">
                        <FontAwesomeButton icon="trash" onClick={() => this.removeFilter(index)} disabled={this.props.disabled || editing} title="Radera filter" />
                    </div>
                </div>
            </React.Fragment>);
        } else {
            return null;
        }
    }
}

export default Skola24FilterInput;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import { getOneRosterUser, getOneRosterUserOrgs, getOneRosterUserClasses } from '../../store/OneRoster/OneRoster_UserAction';
import StatusCode from '../../util/StatusCode';
import * as Helper from '../../util/Helper';
import TableVertical from '../misc/TableVertical';
import TableHorizontalPagedToggle from '../misc/TableHorizontalPagedToggle';
import * as OneRosterRoleType from '../../util/OneRoster/OneRosterRoleTypeUtil';
import SelectInput from '../misc/SelectInput';
import RenderTabs from '../misc/Tabs';
import * as Tab from '../../util/TabValue';
import ActiveToggle from '../misc/ActiveToggle';
import * as OneRosterClassStatus from '../../util/OneRoster/OneRosterClassStatusUtil';


export default () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.oneroster_user.user);

    useEffect(() => {
        getOneRosterUser(userId)(dispatch);
    }, [dispatch]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.UserSchoolUnits,
        Tab.Tabs.Classes,
    ];

    const displayName = user?.data?.familyName ? ` / ${user.data.givenName} ${user.data.familyName}` : '';

    if (user?.error === 400 || user?.error === 404) {
        return <NotFound />;
    }

    return (
        <div className="padding-40 container single">
            <h1><Link to="/oneroster/user">Användare</Link> {displayName}</h1>

            <RenderTabs
                tabs={tabs}
                id={userId}
                url="oneroster/user" />

            <Routes>
                <Route path={Tab.Tabs.Information.url} element={<Info />} />
                <Route path={Tab.Tabs.UserSchoolUnits.url} element={<SchoolUnits />} />
                <Route path={Tab.Tabs.Classes.url} element={<Classes />} />
                <Route path="*" element={<Navigate replace={true} to={`/oneroster/user/${userId}/${Tab.Tabs.Information.url}`} />} />
            </Routes>
        </div>
    );
}

export const NotFound = () => {
    return (
        <div className="padding-40 container single">
            <h1><Link to="/group">Användare</Link></h1>
            <p>Användaren hittades inte. Gör en ny <Link to='/oneroster/user'>sökning</Link></p>
        </div>
    );
}

export const Info = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.oneroster_user.user);

    useEffect(() => {
        getOneRosterUser(userId)(dispatch);
    }, [dispatch]);

    const renderUserInfo = () => {
        let userInfo = [];
        if (user.code === StatusCode.COMPLETE) {
            const u = user.data;
            userInfo = [
                { header: 'Namn', content: `${u.givenName} ${u.familyName}` },
                { header: 'SIS ID', content: u.id },
                { header: 'Aktiv', content: Helper.active(u.active) },
                { header: 'Användarnamn', content: u.userName },
                { header: 'Email', content: u.email },
                { header: 'Roll', content: OneRosterRoleType.roleFromId(u.role) },
                { header: 'Skapad', content: Helper.formatDate(u.created), classname: 'nowrap' },
                { header: 'Ändrad', content: Helper.formatDate(u.modified), classname: 'nowrap' },
            ];
        }
        return (
            <TableVertical
                header='Information'
                info={userInfo}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderUserInfo()}
    </>)
}

export const SchoolUnits = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const userSchools = useSelector(state => state.oneroster_user.userSchool);
    const [show, setShow] = useState(true);

    useEffect(() => {
        getOneRosterUserOrgs(userId, {})(dispatch);
    }, [dispatch]);

    const updateFilter = (value, name) => {
        const filter = { ...userSchools.filter };
        if (name === "onlyActive") {
            filter["page"] = 1;
        }
        getOneRosterUserOrgs(userId, { ...filter, [name]: value })(dispatch);
    }

    const renderSchoolInfo = () => {
        const schoolInfo = userSchools.data?.values.map(us => ({
            id: us.id,
            data: [
                { value: <Link to={`/oneroster/schoolunit/${us.id}/information`}>{us.name}</Link> },
                { value: us.active ? 'Ja' : 'Nej' },
                { value: Helper.formatDate(us.created), classname: 'nowrap' },
                { value: Helper.formatDate(us.modified), classname: 'nowrap' },
            ]
        })) ?? [];

        return (
            <TableHorizontalPagedToggle
                header={[{ value: 'Namn' }, { value: 'Aktiv' }, { value: 'Skapad' }, { value: 'Ändrad' }]}
                info={schoolInfo}
                show={show}
                toggle={() => setShow(!show)}
                onChangePage={(p) => updateFilter(p, 'page')}
                noResultMsg="Den här användaren saknar skolkopplingar"
                requestState={userSchools}
            />
        )
    }

    const count = Helper.getTotalCountText(userSchools)
    return (<>
        <div className="header_member_table large">Skolkopplingar {count}
            <span className="right">
                <ActiveToggle
                    onlyActive={userSchools.filter?.onlyActive ?? null}
                    update={(active) => updateFilter(active, 'onlyActive')}
                    disabled={userSchools.code === StatusCode.PENDING}
                />
            </span></div>
        {renderSchoolInfo()}
    </>);
}

export const Classes = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const userClasses = useSelector(state => state.oneroster_user.userClasses);
    const [show, setShow] = useState(true);

    useEffect(() => {
        getOneRosterUserClasses(userId, {})(dispatch);
    }, [dispatch]);

    const updateFilter = (value, name) => {
        const filter = { ...userClasses.filter };
        if (name !== 'page') {
            filter["page"] = 1;
        }
        getOneRosterUserClasses(userId, { ...filter, [name]: value })(dispatch);
    }

    const renderClasses = () => {
        const classInfo = userClasses.data?.values.map(uc => ({
            id: uc.id,
            data: [
                { value: <Link to={`/oneroster/class/${uc.id}/information`}>{uc.title}</Link> },
                { value: uc.enrollmentActive ? 'Aktiv' : 'Inaktiv' },
                { value: OneRosterClassStatus.statusFromId(uc.status) },
                { value: <Link to={`/oneroster/schoolunit/${uc.schoolId}/information`}>{uc.schoolName}</Link> },
                { value: Helper.formatDate(uc.created), classname: 'nowrap' },
                { value: Helper.formatDate(uc.modified), classname: 'nowrap' },
                { value: Helper.formatDate(uc.inactivated), classname: 'nowrap' },
                { value: Helper.formatDate(uc.archived), classname: 'nowrap' },
            ]
        })) ?? [];

        return (
            <TableHorizontalPagedToggle
                header={[{ value: 'Namn' }, { value: 'Kopplingsstatus'}, { value: 'Avsnittstatus' }, { value: 'Skola' }, { value: 'Skapad' }, { value: 'Ändrad' }, { value: 'Inaktiverad' }, { value: 'Arkiverad' }]}
                info={classInfo}
                show={show}
                toggle={() => setShow(!show)}
                onChangePage={(p) => updateFilter(p, 'page')}
                noResultMsg="Den här användaren saknar matchande avsnitt"
                requestState={userClasses}
            />
        )
    }

    const count = Helper.getTotalCountText(userClasses)
    return (<>
        <div className="header_member_table large select-status">Avsnitt {count}
            <span className="right">
                <SelectInput
                    items={OneRosterClassStatus.StatusTypes.map(statusType => ({ value: statusType.id, text: `Visa avsnitt med status ${statusType.name}` }))}
                    onChange={(name, value) => updateFilter(value, name)}
                    name="status"
                    selected={userClasses?.filter?.status}
                    defaultText="Visa alla avsnitt"
                />
            </span></div>
        {renderClasses()}
    </>);
}

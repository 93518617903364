import React from 'react';
import { Table, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import CheckboxListItem from '../../../misc/CheckboxListItem';
import EnumInput from '../../../misc/EnumInput';
import NullableIntInputCheckboxText from '../../../misc/NullableIntInputCheckboxText';
import { getSystemNames } from '../../../../store/ModuleAction';
import StatusCode from '../../../../util/StatusCode';
import { connect } from 'react-redux';

const memberMode = [
    { value: 0, text: 'Strikt: De som lagts till manuellt tas bort' },
    { value: 1, text: 'Passivt: De som läggs till manuellt får ligga kvar' },
];

const namingStrategy = [
    { value: 1, text: 'Aktivitetsnamn (t.ex. 7A Ma)' },
    { value: 2, text: 'Aktivitetsnamn + Skolnamn (t.ex. 7A Ma Eventfulskolan)' },
    { value: 3, text: 'Aktivitetsnamn + Skolförkortning med fallback på skolnamn (t.ex. 7A Ma EVT)' },
];

class ScheduleSyncGoogle extends ConfigurationComponent {
    state = {
        open: true,
        aliasTrackingModuleTypeChecked: this.props.configuration.aliasTrackingModuleType ? true : false,
    }

    componentDidMount() {
        this.props.getSystemNames();
    }

    validate(configuration, active) {
        const validation = [];
        
        if (!(configuration.archiveGracePeriod >= 0) || configuration.archiveGracePeriod === null) {
            validation.push('archiveGracePeriod')
        }
        if (Number.isNaN(configuration.deleteGracePeriod)) {
            validation.push('deleteGracePeriod');
        }

        return validation;
    }

    warnings(update) {
        const warningMessages = [];

        if (update.deleteGracePeriod === 0) {
            warningMessages.push(`Ingen raderingstid är satt vilket innebär att classroom tas bort permanent direkt efter arkivering`)
        }

        return warningMessages;
    }

    addServiceAccount = (e, serviceAccounts) => {
        e.preventDefault();
        const newServiceAccount = e.target.newServiceAccount.value;
        e.target.newServiceAccount.value = "";

        if (serviceAccounts && serviceAccounts.some(sa => sa.emailAddress === newServiceAccount)) {
            return;
        }
        const updatedServiceAccounts = [...serviceAccounts, { emailAddress: newServiceAccount, classroomOwnerCount: 0 }]
        this.updatePropertyValue("serviceAccounts", updatedServiceAccounts);
    }

    removeServiceAccount = (index, serviceAccounts) => {
        const updatedServiceAccounts = [...serviceAccounts];
        updatedServiceAccounts.splice(index, 1);
        this.updatePropertyValue("serviceAccounts", updatedServiceAccounts);
    }

    updateAliasTrackingModuleType = (prop, value) => {
        var newUpdates = { ...this.props.updates };
        const aliasTrackingModuleTypeValue = (prop === 'aliasTrackingModuleTypeChecked') ? value : this.state.aliasTrackingModuleTypeChecked;

        if (prop === 'aliasTrackingModuleTypeChecked') {
            this.setState({ aliasTrackingModuleTypeChecked: !this.state.aliasTrackingModuleTypeChecked })
        }

        if (this.props.configuration[prop] === value) {
            delete newUpdates[prop];
        } else {
            newUpdates[prop] = value;
        }

        if (prop === 'aliasTrackingModuleTypeChecked' && value === false) {
            if (this.props.configuration.aliasTrackingModuleType) {
                newUpdates.aliasTrackingModuleType = '';
            }
            if (newUpdates.aliasTrackingModuleType) {
                delete newUpdates.aliasTrackingModuleType;
            }
        }

        if ((prop === 'aliasTrackingModuleTypeChecked' && value === true) && (this.props.configuration.aliasTrackingModuleType)) {
            newUpdates.aliasTrackingModuleType = this.props.configuration.aliasTrackingModuleType;
        }
        if (prop === 'aliasTrackingModuleTypeChecked') {
            delete newUpdates.aliasTrackingModuleTypeChecked;
        }

        this.notifyUpdates(newUpdates);
        this.updateData(newUpdates, aliasTrackingModuleTypeValue);
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);
        const aliasTrackingModuleTypeChecked = this.state.aliasTrackingModuleTypeChecked;

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <FormGroup>
                                <h5>Typ av team</h5>
                                <CheckboxListItem text='Klass'
                                    name='includeClasses'
                                    value={configuration.includeClasses}
                                    onChange={this.updateProperty}
                                    disabled={this.props.disableInput}
                                />
                                <CheckboxListItem text='Kurs'
                                    name='includeCourses'
                                    value={configuration.includeCourses}
                                    onChange={this.updateProperty}
                                    disabled={this.props.disableInput}
                                />
                            </FormGroup>

                            <EnumInput
                                title='Namngivning'
                                name='classroomNaming'
                                value={configuration.classroomNaming}
                                options={namingStrategy}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <EnumInput
                                title='Hantera elever'
                                name='studentMemberMode'
                                value={configuration.studentMemberMode}
                                options={memberMode}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <EnumInput
                                title='Hantera lärare'
                                name='teacherMemberMode'
                                value={configuration.teacherMemberMode}
                                options={memberMode}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <FormGroup>
                                <h5>Livscykelhantering</h5>
                                <FormGroup>
                                    <Label for="archiveGracePeriod">Arkivera (dagar efter inaktivering i FlowSync):</Label>
                                    <Input className="md-w-50 lg-w-25" type="number" name="archiveGracePeriod" id="archiveGracePeriod" min="0" step="1" pattern="[0-9]" disabled={this.props.disableInput} value={configuration.archiveGracePeriod === null ? '' : configuration.archiveGracePeriod} onChange={this.updateProperty} invalid={validation.indexOf('archiveGracePeriod') !== -1} />
                                    <FormFeedback>Ange dagar till arkivering</FormFeedback>
                                </FormGroup>
                               
                                <NullableIntInputCheckboxText
                                    name="deleteGracePeriod"
                                    value={configuration.deleteGracePeriod}
                                    disabled={this.props.disableInput}
                                    title="Ta bort (dagar efter arkivering i Google Workspace):"
                                    invalidFeedback="Ange dagar till radering efter arkivering av classroom"
                                    invalid={validation.indexOf('deleteGracePeriod') !== -1}
                                    defaultValue={365}
                                    onChange={(k, v) => this.updatePropertyValue(k, v)}
                                    checkboxTitle="Ingen radering"
                                />
                            </FormGroup>

                            <FormGroup>
                                <h5>Servicekonton</h5>
                                <Form onSubmit={(e) => this.addServiceAccount(e, configuration.serviceAccounts)}>
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <th>E-postaddress</th>
                                                <th>Google-id</th>
                                                <th className='text-right'>Ägare till antal classroom</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {configuration.serviceAccounts.map((acc, index) => (
                                                <tr key={acc.id ? acc.id : acc.emailAddress}>
                                                    <td>{acc.emailAddress}</td>
                                                    <td>{acc.googleId}</td>
                                                    <td className='text-right'>{acc.classroomOwnerCount}</td>
                                                    <td className='text-right'>
                                                        <Button color="danger" onClick={() => this.removeServiceAccount(index, configuration.serviceAccounts)} disabled={this.props.disableInput || acc.classroomOwnerCount > 0}>Ta bort</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={3}>
                                                    <Input type="email" name="newServiceAccount" disabled={this.props.disableInput} required placeholder="E-postaddress" />
                                                </td>
                                                <td className='text-right'>
                                                    <Button color="primary" type="submit" disabled={this.props.disableInput}>Lägg till</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </FormGroup>
                            <FormGroup>
                                <h5>Id från elevregister som alias</h5>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" id="aliasTrackingModuleTypeCheck" checked={aliasTrackingModuleTypeChecked} disabled={this.props.disableInput} onChange={(e) => this.updateAliasTrackingModuleType('aliasTrackingModuleTypeChecked', e.target.checked)} />
                                        Skicka vidare id från elevregister
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="aliasTrackingModuleType">Välj importmodultyp där id:n hämtas</Label>
                                    <Input type="select" name="aliasTrackingModuleType" id="aliasTrackingModuleType" onChange={(e) => this.updateAliasTrackingModuleType('aliasTrackingModuleType', e.target.value)} disabled={!aliasTrackingModuleTypeChecked || this.props.disableInput} value={configuration.aliasTrackingModuleType || ''} invalid={validation.indexOf('aliasTrackingModuleType') !== -1} >
                                        <option value='' disabled>Lägg till alias med id:n från modultyp</option>
                                        {this.props.systemNames.code === StatusCode.COMPLETE && this.props.systemNames.data.map((type, i) => <option key={i} value={type}>{type}</option>)}
                                    </Input>
                                    <FormFeedback>Välj modultyp för att skicka vidare id från elevregister</FormFeedback>
                                </FormGroup>
                            </FormGroup>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

const mapStateToProps = state => ({
    systemNames: state.module.systemNames
})

export default connect(mapStateToProps, { getSystemNames })(ScheduleSyncGoogle);
